import React, { useState } from "react";
import styles from "./reviews.module.css";

// import StarRating from "../components/RatingStars";
// import ReviewFeedback from "../components/ReviewFeedback";

const containerStyles = {
  display: "flex",
  alignItems: "center",
  gap: "16px",
  justifyContent: "center",
  margin: "50px",
};

function StarRating({
  maxRating = 5,
  color = "#fcc419",
  size = 48,
  className = "",
  messages = [],
  defaultRating = 0,
  onSetRating,
}) {
  const [rating, setRating] = useState(defaultRating);
  const [tempRating, setTempRating] = useState(null);

  function handleRatingChange(newRating) {
    setRating(newRating);
    onSetRating(newRating);

    if (newRating >= 4) {
      window.open(
        "https://search.google.com/local/writereview?placeid=ChIJjXwocH7nJIgRLE0L1AU6_EM",
        "_blank"
      );
    }
  }

  const textStyle = {
    lineHeight: "0",
    margin: "0",
    color,
    fontSize: `${size / 1.5}px`,
  };

  const starContainerStyle = {
    display: "flex",
  };

  return (
    <div style={containerStyles} className={className}>
      <div style={starContainerStyle}>
        {Array.from({ length: maxRating }, (_, i) => (
          <Star
            key={i}
            full={
              tempRating !== null
                ? i + 1 <= tempRating
                : rating !== null && i + 1 <= rating
            }
            onRate={() => handleRatingChange(i + 1)}
            onHoverIn={() => setTempRating(i + 1)}
            onHoverOut={() => setTempRating(null)}
            color={color}
            size={size}
          />
        ))}
      </div>
      <p style={textStyle}>
        {messages.length === maxRating
          ? messages[tempRating !== null ? tempRating - 1 : rating - 1]
          : tempRating || rating || ""}
      </p>
    </div>
  );
}

function Star({ onRate, full, onHoverIn, onHoverOut, color, size }) {
  const starStyle = {
    width: `${size}px`,
    height: `${size}px`,
    display: "block",
    cursor: "pointer",
  };

  return (
    <span
      role="button"
      style={starStyle}
      onClick={onRate}
      onMouseEnter={onHoverIn}
      onMouseLeave={onHoverOut}
    >
      {full ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill={color}
          stroke={color}
        >
          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke={color}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="{2}"
            d="M11.049 2.927c.3-.921 1.603-.921 1.902 0l1.519 4.674a1 1 0 00.95.69h4.915c.969 0 1.371 1.24.588 1.81l-3.976 2.888a1 1 0 00-.363 1.118l1.518 4.674c.3.922-.755 1.688-1.538 1.118l-3.976-2.888a1 1 0 00-1.176 0l-3.976 2.888c-.783.57-1.838-.197-1.538-1.118l1.518-4.674a1 1 0 00-.363-1.118l-3.976-2.888c-.784-.57-.38-1.81.588-1.81h4.914a1 1 0 00.951-.69l1.519-4.674z"
          />
        </svg>
      )}
    </span>
  );
}

function ReviewFeedback() {
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");

  function handleNameChange(e) {
    const name = e.target.value;
    setName(name);
  }

  function handleEmailChange(e) {
    const emailVal = e.target.value;
    setEmail(emailVal);
  }

  function handleFeedbackChange(e) {
    const feedback = e.target.value;
    setFeedback(feedback);
  }

  function handleSubmitForm(e) {
    // e.preventDefault();
    alert("Submitting");
  }

  return (
    <div className={styles.reviewContainer}>
      <div className={styles.floatingCard}>
        <h1>Sorry to hear you are not satisfied</h1>
        <h2>
          Please provide us with feedback and we'll do our best to improve.
        </h2>

        <h3>Name(Optional)</h3>
        <form onSubmit={handleSubmitForm}>
          <label htmlFor="Name"></label>
          <input
            type="text"
            name="Name"
            id="Name"
            onChange={handleNameChange}
            value={Name}
          ></input>

          <h3>Email(Optional)</h3>
          <label htmlFor="email"></label>
          <input
            type="text"
            name="email"
            id="email"
            onChange={handleEmailChange}
            value={email}
          ></input>

          <h3>Your Feedback (Please let us know what we can do to improve)</h3>
          <label for="feedback"></label>
          <textarea
            id="feedback"
            name="feedback"
            rows="6"
            cols="50"
            onChange={handleFeedbackChange}
            value={feedback}
          ></textarea>
          <button>Submit</button>
        </form>
      </div>
    </div>
  );
}

function Reviews() {
  const [rating, setRating] = useState(null);

  return (
    // Parent container positioned relatively
    <div className={styles.reviewContainer} style={{ position: "relative" }}>
      <div className={styles.floatingCard}>
        <h1 className={styles.header}>Rate Us</h1>
        {/* Pass the rating setter to StarRating */}
        <StarRating onSetRating={setRating} defaultRating={null} />
      </div>

      {/* Conditionally render the feedback overlay for ratings 1, 2, or 3 */}
      {rating !== null && rating < 4 && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 10,
            background: "rgba(255, 255, 255, 0.8)",
          }}
        >
          <ReviewFeedback />
        </div>
      )}
    </div>
  );
}

export default Reviews;
