import React, { useState } from "react";
import styles from "./catering.module.css";

// Components
import HeadingTwo from "../components/HeadingTwo";
import StarRating from "../components/RatingStars";

// Images
import TacoBarImage from "../assets/images/catering-1.jpeg";

function RatingContainer() {
  const [rating, setRating] = useState(0);

  function handleRatingChange(r) {
    setRating(r);
    alert("New rating!");
  }

  return (
    <>
      <StarRating
        maxRating={5}
        onSetRating={handleRatingChange}
        messages={["Terrible 🗑️", "Bad 👎", "Ok", "Good 👍", "Amazing! 🎉"]}
      />
      <p>The rating was {rating} stars</p>
    </>
  );
}

function Catering() {
  return (
    <div className={styles.cateringPage}>
      <div className={styles.floatingCard}>
        <div className={styles.innerContainer}>
          <h1 className={styles.cateringMenuHeading}>🌮 Catering Club 🌮</h1>
          <HeadingTwo>
            Enjoy holiday magic with Se<span>&#241;</span>oritas catering
          </HeadingTwo>
          <hr className={styles.horizontalLine} />
          <HeadingTwo>TAQUIZAS</HeadingTwo>
          <div className={styles.cateringOptionsContainer}>
            <div className={`${styles.cateringOption} ${styles.optionOne}`}>
              <h3>Taco Bar #1 - $12 per person</h3>
              <ul>
                <li>One choice of meat (beef or chicken)</li>
                <li>Rice and beans</li>
                <li>Chips and salsa</li>
                <li>Taco toppings (cilantro, onions, limes, spicy salsa)</li>
              </ul>
            </div>

            <div className={`${styles.cateringOption} ${styles.optionTwo}`}>
              <h3>Appetizer Party - $90</h3>
              <p>Feeds 8-12 people</p>
              <ul>
                <li>Guacamole Tray (32oz size)</li>
                <li>Pico de Gallo (32oz size)</li>
                <li>Queso dip (32oz size)</li>
                <li>Chips included</li>
              </ul>
            </div>

            <div className={`${styles.cateringOption} ${styles.optionThree}`}>
              <h3>Taco Bar #2 - $13 per person</h3>
              <ul>
                <li>
                  Two choices of meat (beef, chicken, steak, carnitas, pastor)
                </li>
                <li>Rice and beans</li>
                <li>Chips and salsa</li>
                <li>Taco toppings (cilantro, onions, limes, spicy salsa)</li>
              </ul>
            </div>

            <div className={`${styles.cateringOption} ${styles.optionFour}`}>
              <h3>Sausage Dipper - $40</h3>
              <p>Feeds 8-10 people</p>
              <ul>
                <li>Chorizo-queso Dip (32oz size)</li>
                <li>Queso cheese</li>
                <li>Chorizo sausage</li>
                <li>Chips included</li>
              </ul>
            </div>

            <div className={`${styles.cateringOption} ${styles.optionFive}`}>
              <h3>Taco Bar #3 - $14 per person</h3>
              <ul>
                <li>
                  Three choices of meat (beef, chicken, steak, carnitas, pastor)
                </li>
                <li>Rice and beans</li>
                <li>Chips and salsa</li>
                <li>Taco toppings (cilantro, onions, limes, spicy salsa)</li>
              </ul>
            </div>

            <div className={`${styles.cateringOption} ${styles.optionSix}`}>
              <h3>Street Corn Party Tray</h3>
              <ul>
                <li>32oz size - $30 (Feeds 8-10 people)</li>
                <li>60oz size - $50 (Feeds 15-18 people)</li>
              </ul>
            </div>

            <div className={`${styles.cateringOption} ${styles.optionSeven}`}>
              <h3>Taquitos Appetizer - $40</h3>
              <p>Feeds 8-10 people</p>
              <ul>
                <li>40 taquitos</li>
                <li>Sour cream (8oz)</li>
                <li>Mild Salsa (12oz)</li>
                <li>Guac (8oz)</li>
              </ul>
            </div>

            <div
              className={`${styles.cateringOption} ${styles.phoneNumberContainer}`}
            >
              <div className={styles.phoneNumberDiv}>
                <HeadingTwo>
                  Give us a call for any questions or inquiries -{" "}
                  <a className={styles.phoneLink} href="tel:586-251-2142">
                    (586) 251-2142
                  </a>
                </HeadingTwo>
              </div>
            </div>
          </div>
          <div className={styles.imageContainer}>
            <img src={TacoBarImage} alt="Taco Bar" />
          </div>
        </div>
      </div>

      <RatingContainer />
    </div>
  );
}

export default Catering;
